import {Doughnut} from 'vue-chartjs';

export default {
  extends: Doughnut,

  props: {
    document: {
      type: Array,
      default: () => {return [];}
    }
  },

  data: () => ({
    chartdata: {
      labels: ['Member insgesamt', 'Registrierte Member', 'Nicht Registrierte Member'],
      datasets: [
        {
          backgroundColor: [
            '#00D8FF',
            '#41B883',
            '#E46651'
          ],
          data: [102, 22, 80]
        }
      ]
    },
    options: {
      responsive: true,
      maintainAspectRatio: false
    }
  }),

  created() {
    this.chartdata.datasets[0].data = this.document;
  },

  mounted() {
    this.renderChart(this.chartdata, this.options);
  }
};
