<template>
  <div>
    <!-- <Snowf v-if="!loading"
      :amount="50"
      :size="5"
      :speed="1.5"
      :wind="0"
      :opacity="0.8"
      :swing="1"
      :image="null"
      :zIndex="null"
      :resize="true"
      color="#fff"
    /> -->
    <topbar drawer :title="navbarTitle"></topbar>
    <v-container v-if="!loading">
      <v-layout column align-center>
        <!-- <h1 style="margin-top: 32px; font-weight: 900;">Wild Bunch wünscht frohe Weihnachten!</h1> -->
        <h1 style="margin-top: 32px; font-weight: 900;">Willkommen im Wild Bunch Hangar</h1>
        <!-- <div style="margin-top: 16px; text-align: center;">Dies ist die erste Iteration von unserem internen Hangar und wir möchten etwaige Bugs und Unannehmlichkeiten zu entschuldigen.
In Zukunft werden wir unseren Hangar mit neuen Modulen erweitern und hoffen, euch eine gute Palttform für das Flottenmanagment ect. bieten zu können.</div> -->
        <div class="small">
          <bar-chart :document="dashboard.chart" />
        </div>
      </v-layout>
      <v-card style="margin-top: 80px; min-height: 733px;" elevation="1">
        <v-card-title>Übersicht aller {{dashboard.hangarSize}} registrierten Schiffe</v-card-title>
        <v-data-table
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :headers="headers"
          :items="dashboard.ships"
          :search="search"
        >
        <template v-slot:top>
          <v-text-field
            v-model="search"
            label="Search"
            class="mx-4 pt-6"
          ></v-text-field>
        </template>
        <template v-slot:[`item.name`]="{ item }">
          <v-hover v-slot="{ hover }">
            <div :class="{ 'custom-anchor': hover }" class="text-truncate" style="cursor: pointer;" @click="shipDetail(item.shipId)">{{ item.name }}</div>
          </v-hover>
        </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
  import Topbar from '../components/Topbar';
  import BarChart from '../components/charts/BarChart';
  // import Snowf from 'vue-snowf';

  export default {
    name: 'Dashboard',

    components: {
      Topbar,
      BarChart
      // Snowf
    },

    data() {
      return {
        navbarTitle: 'Dashboard',
        search: '',
        sortBy: 'quantity',
        sortDesc: true,
        headers: [
          { text: 'Name', value: 'name' },
          { text: 'Anzahl', value: 'quantity' }
        ],
      };
    },

    computed: {
      loading() {
        return this.$store.getters.loading;
      },
      dashboard() {
        return this.$store.getters['dashboard/getDashboard'];
      }
    },

    beforeMount() {
      this.loadItems();
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    },

    methods: {
      async loadItems() {
        this.$store.dispatch('loading', true, {root: true});
        await this.$store.dispatch('dashboard/getDashboard');
        this.$store.dispatch('loading', false, {root: true});
      },
      shipDetail(shipId) {
        this.$router.push({name: 'ShipDetail', query: {shipId}});
      }
    }
  };
</script>

<style>
  .small {
    z-index: 1;
    max-width: 600px;
    margin-top: 60px;
  }
  .custom-anchor {
    color: skyblue;
    text-decoration: underline;
  }
</style>
